import api from "@/api";
import Order from "@/types/Order";
import PaginatedResponse from "../../types/PaginatedResponse";

async function getUserTickets(page = 1, per_page = 5, eventName = "", status = ""): Promise<PaginatedResponse<Order>> {
  if (eventName == null) eventName = "";

  const response = await api.get<PaginatedResponse<Order>>(
    `/api/user/tickets?page=${page}&per_page=${per_page}&name=${eventName}&status=${status}`
  );
  return response.data;
}

export default getUserTickets;
