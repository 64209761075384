













































import Vue from "vue";
import PaymentCard from "@/components/Ticket/PaymentCard.vue";
import getUserTickets from "@/api/requests/getUserTickets";
import Order from "@/types/Order";

export default Vue.extend({
  name: "ListingTicketMobileByType",
  components: {
    PaymentCard,
  },
  props: {
    filter: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    firstLoading: true,
    payments: [] as Order[],
    page: 1,
    perPage: 6,
    totalPages: 0,
  }),
  created() {
    this.fetchUserTicketsByType();
  },
  methods: {
    async fetchUserTicketsByType() {
      try {
        this.loading = true;
        const res = await getUserTickets(this.page, this.perPage, "", this.filter);
        this.$vuetify.goTo(Number(1), {
          duration: 300,
          offset: 0,
          easing: "easeInCubic",
        });
        this.payments = res.data;
        this.totalPages = res.last_page;
      } catch (error) {
        this.$snackbar("Erro ao buscar lista de ingressos", "error");
      } finally {
        this.firstLoading = false;
        this.loading = false;
      }
    },
    listTickets(payment: Order) {
      try {
        this.$store.commit("ticket/setSelectedOrder", payment);
        this.$router.push(`list/${payment.id}`);
      } catch {
        this.$snackbar("Erro ao carregar os detalhes do pagamento. Tente novamente", "error");
      }
    },
    showMessageEmptyList() {
      switch (this.filter) {
        case "Confirmado":
          return "Não há registro de pedidos Pagos!";

        case "Aguardando Pagamento":
          return "Não há registro de pedidos Aguardando Pagamento!";

        case "Recusado":
          return "Não há registro de pedidos Cancelados!";
      }
    },
  },
});
