


















































































import Vue, { PropType } from "vue";
import TBtn from "@/components/core/TBtn.vue";
import Order from "@/types/Order";
import { DateTime } from "luxon";
import TEventLogo from "@/components/core/Events/TEventLogo.vue";
import currency from "@/filters/currency";

export default Vue.extend({
  name: "PaymentCard",

  components: {
    TBtn,
    TEventLogo,
  },

  data() {
    return {
      luxon: DateTime,
    };
  },

  props: {
    order: {
      type: Object as PropType<Order>,
    },
    buttonText: {
      type: String,
      default: "Imprimir Ingresso",
    },
    buttonCondition: {
      type: Boolean,
      default: true,
    },
    hasIcon: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    loadImagemEscudo(): string {
      return `https://office.ingressodevantagens.com.br/img/evento/${this.order.event.id}/imagemImpressao`;
    },
    loadImagemEscudoVisitante(): string {
      return `https://office.ingressodevantagens.com.br/evento/${this.order.event.id}/imagemEscudoVisitante`;
    },
    getPaymentTypeName(type: number) {
      return ["", "Cartão de Crédito", "Boleto", "Débito Online", "Cartão de Débito", "PIX", "Cortesia"][type];
    },
    currency,
  },
  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    normalizedValue(): string {
      const value = this.order.valor_total.replace(",", ".");
      return parseFloat(value).toFixed(2).toString().replace(".", ",");
    },
    paymentStatus(): string {
      switch (this.order.situacao) {
        case "Cancelado":
          return "Cancelado";
        case "Confirmado":
          return "Pago";
        case "Cortesia":
          return "Cortesia";
        case "Recusado":
          return "Falha no pagamento";
        default:
          return "Aguardando Pagamento";
      }
    },
    showProceedButton(): boolean {
      const allowed = ["Confirmado", "Aguardando Pagamento", "Cortesia"];
      return allowed.includes(this.order.situacao);
    },
    chipColor(): string {
      switch (this.order.situacao) {
        case "Cancelado":
          return "red";
        case "Confirmado":
        case "Cortesia":
          return "green";
        case "Recusado":
          return "red";
        default:
          return "blue";
      }
    },
    amount(): number {
      return this.order.items.reduce((acc, item) => (acc += item.valor), 0);
    },
    fees(): number {
      return this.order.items.reduce((acc, item) => (acc += parseFloat(item.taxa_conveniencia)), 0);
    },
  },
});
