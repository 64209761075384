












































import Vue from "vue";
import Order from "@/types/Order";
import TCategoryDivider from "@/components/core/TCategoryDivider.vue";
import getUserTickets from "@/api/requests/getUserTickets";
import PaymentSkeleton from "@/components/Ticket/PaymentSkeleton.vue";
import DesktopListing from "./Listings/DesktopListing.vue";
import MobileListing from "./Listings/MobileListing.vue";

export default Vue.extend({
  name: "PaymentListing",
  components: {
    TCategoryDivider,
    PaymentSkeleton,
    DesktopListing,
    MobileListing,
  },
  data: () => ({
    loading: true,
    page: 1,
    perPage: 6,
    totalPages: 0,
    neverFiltered: true,
    filters: {
      name: "",
      status: {
        selected: "Confirmado",
        items: [
          { text: "Pagos", value: "Confirmado" },
          { text: "Cortesias", value: "Cortesia" },
          { text: "Aguardando Pagamento", value: "Aguardando Pagamento" },
          { text: "Cancelados", value: "Recusado" },
        ],
      },
    },
  }),
  created() {
    if (this.$route.query.status !== undefined) {
      this.filters.status.selected = this.$route.query.status as string;
    }

    if (this.userIsLogged && !this.isMobile) {
      this.fetchPayments();
    } else {
      this.loading = false;
    }
  },
  methods: {
    async fetchPayments(page = 1) {
      try {
        this.neverFiltered = false;
        this.page = page;
        this.loading = true;

        // At the request of M, the page should load with the paid *and* courtesy tickets.
        // Therefore, the status is now a csv of possible statuses. Sorry.
        let status = this.filters.status.selected;
        if (status === "Confirmado") {
          status += ",Cortesia";
        }

        const response = await getUserTickets(this.page, this.perPage, this.filters.name, status);

        this.$store.commit("ticket/setOrders", response.data);
        this.totalPages = response.last_page;
      } catch (error) {
        this.totalPages = 1;
        this.$snackbar("Não foi possível buscar seus ingressos. Tente mais tarde.", "error");
      } finally {
        this.loading = false;
      }
    },
    async cleanFilters() {
      this.filters.name = "";
      this.filters.status.selected = "Confirmado";

      try {
        this.neverFiltered = true;
        this.page = 1;
        this.loading = true;

        const response = await getUserTickets(this.page, this.perPage, "", "Confirmado");

        this.$store.commit("ticket/setOrders", response.data);
        this.totalPages = response.last_page;
      } catch (error) {
        this.totalPages = 1;
        this.$snackbar("Não foi possível buscar seus ingressos. Tente mais tarde.", "error");
      } finally {
        this.loading = false;
      }
    },
    listTickets(payment: Order) {
      try {
        this.$store.commit("ticket/setSelectedOrder", payment);
        this.$router.push(`list/${payment.id}`);
      } catch {
        this.$snackbar("Erro ao carregar os detalhes do pagamento. Tente novamente", "error");
      }
    },
  },
  computed: {
    userIsLogged(): boolean {
      return this.$store.state.user.logged;
    },
    payments(): Order[] {
      return this.$store.state.ticket.orders;
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.xs;
    },
  },
  watch: {
    userIsLogged(newValue) {
      if (newValue) {
        this.fetchPayments();
      }
    },
  },
});
