


























import Vue from "vue";
import List from "./Tabs/List.vue";

export default Vue.extend({
  name: "DesktopPaymentListing",
  components: { List },
  props: {},
  data: () => ({
    currentTab: 0,
  }),
  computed: {
    tabs() {
      return [
        {
          title: "Pagos",
          component: List,
          props: {
            filter: "Confirmado,Cortesia",
          },
        },
        {
          title: "Cortesias",
          component: List,
          props: {
            filter: "Cortesia",
          },
        },
        {
          title: "Aguardando Pgto",
          component: List,
          props: {
            filter: "Aguardando Pagamento",
          },
        },
        {
          title: "Cancelados",
          component: List,
          props: {
            filter: "Recusado",
          },
        },
      ];
    },
  },
});
