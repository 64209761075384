










import Vue from "vue";

export default Vue.extend({
  name: "PaymentSkeleton",
  props: {
    quantity: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    list(): number[] {
      return Array.from(Array(this.quantity).keys());
    },
  },
});
