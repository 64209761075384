









































































import Vue from "vue";
import PaymentCard from "@/components/Ticket/PaymentCard.vue";
import Order from "@/types/Order";

export default Vue.extend({
  name: "DesktopPaymentListing",
  components: {
    PaymentCard,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    fetchPayments: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    neverFiltered: {
      type: Boolean,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
  },
  data: () => ({}),
  computed: {
    payments(): Order[] {
      return this.$store.state.ticket.orders;
    },
  },
  methods: {
    listTickets(payment: Order) {
      try {
        this.$store.commit("ticket/setSelectedOrder", payment);
        this.$router.push(`list/${payment.id}`);
      } catch {
        this.$snackbar("Erro ao carregar os detalhes do pagamento. Tente novamente", "error");
      }
    },
  },
});
